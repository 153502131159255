@import '../../../variables.css';

.trackCard {
  display: flex;
  padding: 13px 11px;
  border-radius: 13px;
  border: 1px solid var(--g5-color);
  background: var(--white-color);
  align-items: center;
  justify-content: space-between;
}

.leftSection {
  display: flex;
}

.image {
  width: 102px;
  height: 102px;
  object-fit: cover;
  margin-right: 17px;
  border-radius: 15px;
  border: 1px solid var(--Colors-Gray-G4, #EBECF0);
}

.infos {
  padding-top: 10px;
}

.title {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}

.metrics {
  color: #999;
  font-size: 12px;
  font-weight: 500;
}

.trackDetailsAction {
  color: var(--blue-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.trackDetailsIcon path {
  fill: var(--blue-color);
}

.details {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--g2-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}

.detailsIcon {
  width: 16px;
  height: 16px;
}

.detailsIcon path {
  fill: var(--g2-color);
}

.detailsContainer {
  display: flex;
  gap: 27px;
}

.button {
  width: 212px;
  height: 50px;
}

.actionsSection {
  display: flex;
  align-items: center;
  gap: 12px;
}

.trackPlannerAction {
  color: var(--Colors-Main-Colors-P1, #176FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}

.trackPlannerIcon {
  margin-left: 10px;
}

.plannerIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}